<template lang='pug'>
	multiselect(
		:multiple="true"
		v-model="innerModel.value"
		:label="label"
		name='id'
		trackBy='id'
		:options='options'
		:placeholder='placeholder'
		:close-on-select='false'
		:show-labels="false"
		@select='changedInner'
		@remove='changedInner'
	)
		.mb-4(slot="noResult") {{ $t('errors.emptySearch') }}
		template(slot="selection" slot-scope="{ values, search, isOpen }")
			.text-muted.mb-2(v-if='values.length == 0 && isOpen') {{ searchTitle }}
			.multiselect__tags--custom(v-else-if='values.length > 2')
				span.multiselect__tag
					span {{ values[0][label] }}
				span.multiselect__tag
					span {{ values[1][label] }}
				span ...
				span.multiselect__tag.multiselect__tag--amount
					span {{ values.length - 2 }}
		template(slot="option" slot-scope="props")
			b-form-checkbox.flex-grow-1(v-model='innerModel.value' :value='props.option' disabled)
				span.text-1-row.text-default {{ props.option[label] }}
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
	name: 'PwSelect',
	components: {
		Multiselect
	},
	props: {
		model: {
			type: Object,
			default : () => ({value: [], title: null})
		},
		options: {
			type: Array,
			default : () => ([])
		},
		placeholder: {
			type: String,
			default: null
		},
		reduce: {
			type: Function,
			default: p => p.id
		},
		label: {
			type: String,
			default: 'title'
		},
		searchTitle: {
			type: String,
			default: ''
		},
	},

	data : () => ({
		innerModel : {value: [], title: null}
	}),
	methods: {
		changedInner() {
			this.$emit('changed', _.map(this.innerModel.value, v => v.id));
		}
	},

	computed : {
		
	},

	mounted () {
		this.innerModel.value = _.map(this.model.value, m => _.find(this.options, o => o.id == m));
	},

	watch: {
		innerModel: {
			handler (v) {
				this.changedInner();
			},
			deep: true
		},
		model: {
			handler (v) {
				if ( v.value == null )
					this.innerModel.value = [];
			},
			deep: true
		}
	}
}
</script>

<style lang='scss' lang='scss'>
	.custom-control-input:disabled {
		border-color: var(--theme-color-1);
	}

	.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
		background: var(--foreground-color);
	}

	.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
		background: var(--theme-color-1);
	}
</style>

<style lang='scss'>
	
</style>