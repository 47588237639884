<template lang='pug'>
	b-modal#modalremovebankcard(ref='modal' size='md' hide-footer hide-header :centered='!isMobile')
		span.btn-close(aria-label="Close" @click="close")
			i.fontello.fontello-icon-cross
		div(v-if='account != null && account.canRemoveAccount()')
			div(v-if='!success')
				h2.modal-xs-title {{ $t('finance.confirmRemoveAccount') }}

				p.mb-4(v-if='account') {{ $t('finance.bankAccount') }} #
					| {{ account.id }}  {{ $t('forms.willBeDeleted2') }}
				.d-flex.d-sm-block.justify-content-between
					b-btn.flex-grow-1.mr-3(variant='outline-primary' @click='close') {{ $t('forms.cancel2') }}
					b-btn.flex-grow-1(variant='danger' @click='send' :disabled='isPending') {{ $t('forms.remove') }}
			div(v-else)
				img.success-icon.mb-3(src='/assets/img/icons/success2.svg')
				h2.mb-4 {{ $t('success.accountDelited') }}
				b-btn.btn-xs-100(variant='primary' @click='close') {{ $t('wizard.success') }}
		div(v-else)
			h2.modal-xs-title.mb-4 {{ $t('finance.cardCantRemove') }}
			p.mb-4 {{ $t('finance.accountCantRemove') }}

			b-btn.btn-xs-100(variant='primary' @click='close') {{ $t('social.understand') }}
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
const { required, numeric, minLength, maxLength } = require("vuelidate/lib/validators")

export default {
	name: 'ModalRemoveAccount',
	components: {
		
	},
	props: {
		account: {
			type: Object,
			default: () => (null)
		}
	},
	data: () => ({
		success: false
	}),
	
	created () {

	},
	methods : {
		...mapActions({
			remove	        : 'finance/removeAccount',
		}),

		close () {
			this.$refs.modal.hide();
		},

		send () {
			this.remove({id: this.account.id})
				.then( i => {
					this.$store.commit('finance/removeAccount', this.account )
					this.success = true;
				})
				.catch( (v) => {
					this.$notify("error", v);
				})
		},
	},
	computed : {
		...mapGetters({
			isPending		: 'finance/isPending',
		}),
	},
	watch : {
		account : {
			handler (nV, oV) {
				if ( nV != null ) {
					this.success = false;
					return this.$refs.modal.show();
				}

				if ( this.$refs.modal )
					this.$refs.modal.hide();
			}
		}
	}
}
</script>
