<template lang='pug'>
	b-form-group(horizontal='' :label="$t('forms.email')" label-for='IEmail' breakpoint='md')
		b-form-input(id='IEmail' name='email' :placeholder='$t("forms.placeholder.email")' v-model.trim='$v.email.$model' :class='{"is-invalid": alreadyAttemptSend && $v.email.$error, "is-valid" : alreadyAttemptSend && !$v.email.$error}' autocomplete="off")
		
		.text-danger.mt-2(v-if='!$v.email.required && alreadyAttemptSend') {{ $t('errors.required.email') }}
		.text-danger.mt-2(v-if='!$v.email.email && alreadyAttemptSend') {{ $t('errors.valid.email') }}

		.text-right.mt-4(v-if='btnTitle')
			b-btn(variant='primary' @click='validate' :disabled='email == null')
				//- span(v-if='btnTitle == ""') {{ $t('wizard.next') }}
				span {{ btnTitle }}
</template>

<script>
const { required, email } = require("vuelidate/lib/validators");
import {bus} from '@/main.js'

export default {
	name: 'EmailCard',
    props: {
		existed: {
			default: null,
			type: String
		},
		btnTitle: {
			default: '',
			type: String
		}
	},
	mounted () {
        bus.$on('validateFormEmail', this.validate);
    },

    destroyed () {
        bus.$off('validateFormEmail', this.validate);
    },
	data: () => ({
		email : null,
		alreadyAttemptSend: false
	}),
	created () {
		if ( this.me && this.me.email )
			this.email = this.me.email;
			
		if ( this.existed ) this.email = this.existed;
	},
    validations: {
        email: {
            required,
			email
		}
    },
	methods : {
		validate() {
			this.$v.$touch();

			this.alreadyAttemptSend = true;
			
			if( this.$v.email.$error ) return;
			
			this.save();
		},
		
		save () {
			this.success();
		},

		success () {
			this.$emit('success', this.email)
		}
	},
    components: {
    
    },

	watch: {
		me (nv) {
			this.email = nv.email;
		}
	}
}
</script>